#SideBar {
  width: 300px;
  padding: 20px;
  position: relative;
  border-right: 1px solid rgba(9, 30, 66, 0.1);

  .CollapseBtn {
    width: 24px;
    height: 24px;
    background: #002395;
    box-shadow: 0px 1px 6px rgba(9, 30, 66, 0.1),
      0px 2px 6px rgba(9, 30, 66, 0.1);
    border-radius: 4px;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;

    position: absolute;
    right: -12px;
  }

  .ant-collapse {
    background: none;
  }

  .ant-collapse-header-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #253858;
  }

  .ant-collapse-content-box {
    padding: 5px;
  }

  .ListCollapse {
    margin: 10px 0;

    .ItemCollapse {
      padding: 10px 16px;
      border-left: 4px solid rgba(9, 30, 66, 0.05);
      cursor: pointer;
      color: rgba(9, 30, 66, 0.6);

      a {
        color: rgba(9, 30, 66, 0.6);
      }

      &.Active {
        border-left-color: #002395;
        background: #f1f9ff;
        color: #253858;
      }
    }
  }
  .version-tag {
    position: absolute;
    bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    color: gray;
    opacity: 0.9;
    font-size: 14px;
    font-weight: 400;
  }
}

#TopBar {
    display: flex;
    border-bottom: 1px solid #c1c9d2;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;

    #TopBar__Left {
        display: flex;
        align-items: center;

        img {
            height: 30px;
            margin-right: 20px;
            cursor: pointer;
        }

        .LeftText {
            font-size: 16px;
            line-height: 24px;
            color: #253858;
            border-left: 1px solid #98a2b3;
            padding-left: 20px;
        }
    }

    #TopBar__Right {
        display: flex;
        align-items: center;

        .MFAInfo {
            font-size: 11px;
        }

        .ButtonContainer {
            display: flex;
            margin-left: 15px;
            padding-left: 15px;
            border-left: 1px solid grey;
            color: #d92768;
            font-weight: 700;
            cursor: pointer;
        }
    }
}

#FormOnlyPdfUpload {
    max-width: 100%;
    margin: 20px 0;

    #LabelFileUpload {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px dashed #7bc7ff;
        border-radius: 8px;
        text-align: center;

        .UploadIcon {
            margin-top: 48px;
            margin-bottom: 30px;
        }

        .UploadText {
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
            letter-spacing: 0.02em;
            color: #42526e;
        }

        .ButtonContainer {
            border: none;
            justify-content: center;
            margin-bottom: 30px;
            margin-top: 20px;

            .Button {
                font-size: 12px !important;
                font-size: 12px !important;
                line-height: 16px !important;
                letter-spacing: 0.02em !important;
                color: #42526e !important;
                display: flex;
                align-items: center;

                svg {
                    color: #091e42;
                    font-size: 16px;
                    stroke-width: 20px;
                    margin-left: 3px;
                }
            }
        }

        .FileContainer {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .ContentContainer {
                .ThumbnailPdf {
                    canvas {
                        width: auto !important;
                        height: calc(100vh - 550px) !important;
                        min-height: 100px !important;
                    }
                }

                .IconContainer {
                    position: absolute;
                    font-size: 24px;
                    cursor: pointer;
                    z-index: 1;

                    width: 32px;
                    height: 32px;
                    left: 6px;
                    top: 6px;
                    background: #ffffff;
                    border: 1px solid #c1c7d0;
                    border-radius: 4px;
                    color: rgba(9, 30, 66, 0.8);
                }
            }
        }
    }

    #LabelFileUpload.drag-active {
        background-color: #ffffff;
    }

    #DragFileElement {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
}

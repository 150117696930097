.UserModalContainer {
    position: relative;
    max-height: calc(100vh - 280px);
    overflow: auto;
    background-color: #fff;

    table {
        min-width: 100%;

        thead {
            tr {
                font-size: 12px;
                line-height: 14px;
                color: #fff;
                letter-spacing: 0.05em;

                th {
                    padding: 10px 0 10px 10px;
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    background: #4472c4;
                }
            }
        }

        tfoot {
            tr {
                td {
                    padding: 10px 0 10px 10px;
                    position: -webkit-sticky;
                    position: sticky;
                    bottom: 0;
                    background: #4472c4;
                    font-size: 12px;
                    line-height: 14px;
                    color: #ffffff;
                }
            }
        }

        th,
        td {
            width: 100px;
            padding: 10px;
            border: 1px solid #d1cfcf;
            vertical-align: top;
            word-break: break-word;
        }
    }
}

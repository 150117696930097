.FlowModal {
    .ModalSearch {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .SearchLabel {
            font-size: 12px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0em;
            color: rgba(145, 158, 171, 1);
            margin-right: 10px;
        }

        .ant-select-selector {
            border-radius: 10px !important;
        }
    }

    .ModalTable {
        border-radius: 10px;
        border: 1px solid rgba(219, 223, 226, 1);

        .TableRow {
            display: flex;

            .FirstCol {
                flex-grow: 1;
                padding: 5px;
                padding-left: 10px;
                margin: 5px;
                display: flex;
                align-items: center;
            }

            .SecondCol {
                width: 120px;
                min-width: 120px;
                padding: 5px;
                padding-left: 10px;
                margin: 5px;
                border-left: 1px solid rgba(219, 223, 226, 1);

                display: flex;
                align-items: center;

                .PriorityInp {
                    input {
                        width: 60px;
                        margin-right: 10px;
                        border-radius: 8px;
                        text-align: center;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        /* display: none; <- Crashes Chrome on hover */
                        -webkit-appearance: none;
                        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
                    }

                    input[type="number"] {
                        -moz-appearance: textfield; /* Firefox */
                    }
                }

                svg {
                    font-size: 25px;
                    color: #727f8d;
                    cursor: pointer;
                }
            }

            &.TableHeader {
                background-color: rgba(231, 234, 236, 1);
                font-weight: 500;
                line-height: 24px;
            }
        }

        .TableEmpty {
            font-weight: 400;
            line-height: 24px;
            color: rgba(145, 158, 171, 1);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
        }
    }
}

#LoginPage {
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  padding-top: 200px;

  #LP__Left {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 330px;
    padding-top: 80px;

    img {
      margin-bottom: 30px;
    }

    .Container {
      .Button {
        width: 100%;
        padding: 0 !important;
      }
    }

    .ant-input {
      border: 1px solid rgba(9, 30, 66, 0.2);
      border-radius: 2px;
    }
  }

  #LP__Right {
    @media only screen and (max-width: 1000px) {
      display: none;
    }

    img {
      width: auto;
      margin-left: 8vw;
    }
  }
}

body {
  font-family: "Inter", sans-serif !important;
  margin: 0px;
  padding: 0px;
  font-weight: 500 !important;
}

.ant-modal {
  top: 2vh !important;
  width: fit-content !important;

  .ant-modal-body {
    padding: 15px !important;
    width: 40vw;
  }
}

.Container {
  width: 100%;
}

.ant-btn-primary {
  background: #002395 !important;
  border: #002395 !important;
}

.ant-btn-dangerous {
  background: #d92768 !important;
  border: #d92768 !important;
}

.rnc__notification-item--danger {
  background-color: #d92768 !important;
  border-left: 8px solid #d92768 !important;
}

.Button {
  align-items: center;
  background: #002395 !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #fff !important;
  border: none !important;

  &.Disabled {
    background: #97a0af !important;
  }

  &.Secondary {
    background: #ffffff !important;
    color: #42526e !important;
    border: 1px solid #c1c7d0 !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    padding: 4px 12px !important;
    line-height: 20px !important;
  }
}

.ButtonApp {
  background: #002395 !important;
  border-radius: 4px !important;
  padding: 8px 12px !important;
  font-weight: 500 !important;
  color: #fff !important;
  height: auto !important;
  border: none !important;
  font-size: 14px !important;
  line-height: 20px !important;

  &.Disabled {
    background: #97a0af !important;
  }

  &.Secondary {
    background: #ffffff !important;
    color: #42526e !important;
    border: 1px solid #c1c7d0 !important;
    border-radius: 4px;
    font-size: 14px !important;
    padding: 6px 12px !important;
    line-height: 20px !important;
  }
}

.AppPage {
  height: calc(100vh - 51px);
  overflow: auto;
  //width: calc(100vw - 300px);
  flex-grow: 1;

  .PageTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #0958ba;
    background: #f1f9ff;
    padding-top: 28px;
    padding-left: 24px;
    padding-bottom: 24px;
  }

  .DailyStat {
    margin: 20px;
    display: flex;
    justify-content: left;

    .StatCard {
      padding: 20px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 5px;
      margin-right: 20px;

      .StatNumber {
        font-size: 32px;
        line-height: 44px;
      }

      &.Wait {
        background-color: rgba(255, 249, 231, 1);
      }
      &.Progress {
        background-color: rgba(240, 249, 255, 1);
      }
      &.Success {
        background-color: rgba(215, 250, 224, 1);
      }
      &.Fail {
        background-color: rgba(251, 234, 229, 1);
      }
    }
  }

  .DailyTab {
    margin: 20px;
    display: flex;
    justify-content: left;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 2px solid rgba(249, 250, 251, 1);

    .TabItem {
      color: rgba(145, 158, 171, 1);
      padding: 10px;
      cursor: pointer;

      &.Active {
        color: rgba(31, 65, 155, 1);
        border-bottom: 2px solid rgba(31, 65, 155, 1);
      }
    }
  }

  .FormFilter {
    margin: 20px;
    margin-bottom: 0;

    .ant-select-selection-placeholder,
    .ant-input-group-addon {
      color: inherit;
      font-weight: 500;
    }

    .ant-form-item {
      margin-right: 10px;
    }

    .ant-picker-input {
      max-width: 143px;
    }

    .ant-select-selection-placeholder {
      color: #bfbfbf;
    }

    .ButtonContainer {
      display: flex;

      .ResetButton {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;

        svg {
          font-size: 18px;
          margin-right: 5px;
        }
      }

      .DownloadButton {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;

        svg {
          font-size: 18px;
          margin-right: 5px;
        }
      }

      button {
        border-radius: 4px;
        padding: 0 8px;
        height: 32px !important;
      }
    }
  }

  .CreateButtonContainer {
    display: flex;
    justify-content: end;
    margin: 10px;

    button {
      border-radius: 4px;
      padding: 0 8px;
      height: 32px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;

      svg {
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }

  .TableContainer {
    position: relative;
    padding: 0 20px;
    height: calc(100vh - 262px);
    overflow: auto;
    background-color: #fff;

    table {
      min-width: 100%;
    }

    thead {
      tr {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.05em;
        color: #002395;
        text-align: left;

        th {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          background: #f2f5ff;
          z-index: 1000;
        }
      }
    }

    th,
    td {
      padding: 10px;
      border: 1px solid #d1cfcf;
      vertical-align: middle;
      word-break: break-word;
      text-align: center;
      min-width: 100px;
      img {
        max-height: 100px;
      }

      .PriorityCell {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .PriorityContainer {
          width: 60px;
          height: 40px;
          border-radius: 8px;
          border: 1.5px solid rgba(231, 234, 236, 1);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .PriorityInp {
          input {
            width: 60px;
            margin-right: 10px;
            border-radius: 8px;
            text-align: center;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
          }

          input[type="number"] {
            -moz-appearance: textfield; /* Firefox */
          }
        }
      }

      .ButtonContainer {
        display: flex;

        button {
          border-radius: 4px;
          padding: 0 8px;
          height: 32px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;

          svg {
            font-size: 18px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .QuantityContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    background-color: #f4f5f7;
  }

  .PageContainer {
    display: flex;
    overflow: auto;

    @media only screen and (max-width: 1100px) {
      display: block;
    }

    .Page__Left {
      width: 50%;
      //height: calc(100vh - 51px);
      //overflow: auto;

      @media only screen and (max-width: 1100px) {
        width: 100%;
      }

      .MainLeft {
        height: calc(100vh - 131px);
        overflow: auto;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 30px;

        .RequirementContainer {
          padding: 20px 0;
          border-bottom: 2px solid #e4e7ec;

          .RequirementItem {
            margin-top: 15px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #42526e;

            display: flex;
            align-items: center;

            .Bullet {
              height: 8px;
              width: 8px;
              border-radius: 50%;
              background: #1780de;
              margin-right: 10px;
            }
          }

          .TabContainer {
            display: flex;
            margin-top: 10px;

            .TabItem {
              color: rgba(9, 30, 66, 0.8);
              border-bottom: 2px solid #e4e7ec;
              padding: 6px 8px;
              cursor: pointer;

              &.Active {
                background: #e4f4ff;
                border-bottom: 2px solid #002395;
              }
            }
          }
        }

        .FilterContainer {
          display: flex;
          margin-top: 10px;

          .ant-select {
            width: 50%;
          }
        }

        .UploadText {
          font-size: 14px;
          line-height: 20px;
          color: rgba(9, 30, 66, 0.8);
          margin-top: 20px;
          text-align: center;
        }

        .UploadContainer {
          display: flex;
          justify-content: space-between;
          text-align: center;
          max-width: 100%;
          overflow: auto;

          .UploadItem {
            min-width: 120px;
            margin-right: 10px;

            &.Full {
              width: 100%;
            }
          }
        }

        .ButtonContainer {
          .ButtonApp {
            width: 100%;
          }
        }

        .TopFileContainer {
          margin: 20px;
          text-align: center;
          img {
            max-height: 250px;
            width: auto;
            max-width: 80%;
          }
        }

        .BottomFileContainer {
          margin-top: 5px;
          display: flex;
          justify-content: center;

          img {
            max-height: 120px;
            width: auto;
            max-width: 80%;
            margin: 5px;
          }
        }

        .OCRListPage {
          max-width: 96px;
        }

        .OCRPaging {
          display: flex;
          align-items: center;
        }
      }

      .ExpandContainer {
        background-color: #091e42;
        height: 100%;
        padding: 25px;
        text-align: center;

        .ExpandController {
          display: flex;
          justify-content: space-between;
          color: #ffffff;
          font-size: 14px;
          line-height: 20px;

          .ControllerContainer {
            display: flex;
            align-items: center;

            span {
              margin: 0 10px;
            }

            svg {
              font-size: 18px;
              cursor: pointer;
            }
          }
        }

        img {
          margin-top: 20px;
          border-radius: 8px;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: calc(100vh - 150px);
        }

        .CanvasContainer {
          display: flex;
          justify-content: center;
          height: calc(100vh - 130px);
          margin-top: 20px;
          overflow: auto;

          .PdfCanvas {
            position: relative;
            width: fit-content;

            img {
              margin-top: 0;
              max-width: 100%;
              max-height: none;
            }

            #DrawCanvas {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              z-index: 1;
            }
          }
        }

        .ThumbnailPdf {
          margin-top: 20px;
          border-radius: 8px;
          position: absolute;

          canvas {
            width: auto !important;
            height: auto !important;
            max-width: 100% !important;
            max-height: calc(100vh - 150px) !important;
          }
        }
      }
    }

    .Page__Right {
      height: calc(100vh - 51px);
      overflow: auto;
      width: 50%;
      padding: 16px 24px;
      border-left: 1px solid #e6e8ed;

      @media only screen and (max-width: 1100px) {
        width: 100%;
      }

      .ResultTitleContainer {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .ResultTitle {
          margin-bottom: 20px;
          margin-top: 20px;
          font-size: 16px;
          line-height: 24px;
          color: #091e42;
          font-size: 18px;
        }

        .ButtonApp {
          height: auto !important;
        }
      }

      table {
        width: 100%;
      }

      table,
      th,
      td {
        border: 1px solid rgba(9, 30, 66, 0.2);
      }

      th {
        background: #e6e8ed;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #253858;
        text-align: left;
        padding: 16px 10px;
      }

      td {
        padding: 10px 8px;

        &.First {
          width: 20%;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #42526e;
        }

        &.Middle {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: rgba(9, 30, 66, 0.8);
          white-space: pre-line;

          &.Null {
            color: rgba(9, 30, 66, 0.4);
          }

          .Editable {
            display: flex;
            align-items: center;
            justify-content: space-between;

            svg {
              font-size: 18px;
              margin-left: 5px;
            }
          }
        }

        &.Last {
          width: 15%;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: rgba(9, 30, 66, 0.8);
        }
      }

      .ContractTable {
        tr {
          &.RowClick {
            cursor: pointer;
            &:hover {
              background-color: lightgray;
            }
          }
        }
      }

      .SignTable {
        td {
          img {
            width: 80%;
          }
          &.First {
            width: 30%;
          }

          &.Middle {
            width: 30%;
          }

          &.Last {
            width: 40%;
            vertical-align: top;

            #Accuracy {
              font-weight: 700;
              font-size: 14px;
              color: #00ce7b;
            }

            #UserID {
              color: #091e42;
            }
          }
        }
      }
    }
  }

  .DetailContainer {
    .PageTitle {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      background: #f4f5f7;
      padding-top: 28px;
      padding-left: 24px;
      padding-bottom: 24px;
      color: var(--neutral-dark-800, #253858);

      svg {
        cursor: pointer;
        margin-right: 5px;
      }
    }

    .InfoContainer {
      margin: 10px 0;
      padding: 0 20px;
      display: flex;

      .InfoCol {
        margin-right: 10%;

        .InfoLabel {
          color: var(--neutral-dark-04, rgba(9, 30, 66, 0.4));
          font-size: 12px;
          font-weight: 700;
          margin-right: 10px;
        }

        .InfoValue {
          color: var(--neutral-dark-800, #253858);
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .OCRContainer {
      display: flex;
      overflow: auto;

      @media only screen and (max-width: 1100px) {
        display: block;
      }

      .Page__Left {
        width: 50%;

        @media only screen and (max-width: 1100px) {
          width: 100%;
        }

        .ExpandContainer {
          background-color: #091e42;
          height: 100%;
          padding: 25px;
          text-align: center;

          .ExpandController {
            display: flex;
            justify-content: space-between;
            color: #ffffff;
            font-size: 14px;
            line-height: 20px;

            .ThumbnailFileContainer {
              margin-top: 5px;
              display: flex;
              overflow: auto;

              .ThumbnailFile {
                min-width: 64px;
                margin: 5px;

                img {
                  width: 64px;
                  height: 64px;
                }
              }
            }

            .ControllerContainer {
              display: flex;
              align-items: center;

              span {
                margin: 0 10px;
              }

              svg {
                font-size: 18px;
                cursor: pointer;
              }
            }
          }

          img {
            margin-top: 20px;
            border-radius: 8px;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: calc(100vh - 150px);
          }

          .CanvasContainer {
            display: flex;
            justify-content: center;
            height: calc(100vh - 360px);
            margin-top: 20px;
            overflow: auto;

            .PdfCanvas {
              position: relative;
              width: auto;
              img {
                margin-top: 0;
                max-width: 100%;
                max-height: none;
              }

              #DrawCanvas {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                z-index: 1;
              }
            }
          }
        }
      }

      .Page__Right {
        height: calc(100vh - 210px);
        overflow: auto;
        width: 50%;
        padding: 0px 24px;
        border-left: 1px solid #e6e8ed;

        @media only screen and (max-width: 1100px) {
          width: 100%;
        }

        .ResultTitleContainer {
          display: flex;
          justify-content: space-between;
          align-items: baseline;

          .ResultTitle {
            margin-bottom: 10px;
            margin-top: 5px;
            font-size: 16px;
            line-height: 24px;
            color: #091e42;
            font-size: 18px;
          }

          .ButtonApp {
            height: auto !important;
          }
        }

        table {
          width: 100%;
        }

        table,
        th,
        td {
          border: 1px solid rgba(9, 30, 66, 0.2);
        }

        th {
          background: #e6e8ed;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #253858;
          text-align: left;
          padding: 16px 10px;
        }

        td {
          padding: 10px 8px;

          &.First {
            width: 20%;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.02em;
            color: #42526e;
          }

          &.Middle {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: rgba(9, 30, 66, 0.8);
            white-space: pre-line;

            &.Null {
              color: rgba(9, 30, 66, 0.4);
            }

            .Editable {
              display: flex;
              align-items: center;
              justify-content: space-between;

              svg {
                font-size: 18px;
                margin-left: 5px;
              }
            }
          }

          &.Last {
            width: 15%;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: rgba(9, 30, 66, 0.8);
          }
        }

        .ContractTable {
          tr {
            &.RowClick {
              cursor: pointer;
              &:hover {
                background-color: lightgray;
              }
            }
          }
        }

        .SignTable {
          td {
            img {
              width: 80%;
            }
            &.First {
              width: 30%;
            }

            &.Middle {
              width: 30%;
            }

            &.Last {
              width: 40%;
              vertical-align: top;

              #Accuracy {
                font-weight: 700;
                font-size: 14px;
                color: #00ce7b;
              }

              #UserID {
                color: #091e42;
              }
            }
          }
        }
      }
    }
  }

  .ReportContainer {
    display: flex;
    flex-wrap: wrap;

    .ReportBlock {
      width: calc(50% - 30px);
      min-width: 500px;
      background-color: #fff;
      margin-left: 20px;
      margin-bottom: 10px;
      padding: 15px;

      .BlockTitle {
        color: #091e42 80%;
        font-size: 20px;
      }

      .BlockTime {
        color: #091e42 60%;
        font-weight: 300;
        font-size: 13px;
        margin-top: 5px;
      }

      .BlockBody {
        &.OCR_Status {
          display: flex;
        }

        .ChartContainer {
          width: 100%;
          aspect-ratio: 1;
          max-height: calc(100vh - 480px);

          .ChartTitle {
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            text-transform: capitalize;
            color: #3c4257;
            margin-bottom: 30px;
          }
        }

        .InfoContainer {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .InfoRow {
            margin-top: 5px;
            padding-bottom: 7px;
            border-bottom: 1px solid rgba(9, 30, 66, 0.2);

            .InfoLabel {
              display: flex;
              align-items: center;
              color: #091e42 60%;
              font-weight: 300;
              font-size: 13px;
              margin-top: 5px;

              svg {
                font-size: 16px;
                margin-right: 6px;
              }
            }
            .InfoValue {
              color: #091e42 80%;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

.MainContent {
  min-height: 100vh;
  position: relative;

  .ContentPage {
    .ant-spin-nested-loading {
      flex: 1;
      position: unset;

      .ant-spin-container {
        position: unset;
      }
    }
  }
}

.ViewContainer {
  height: calc(90vh - 70px);
  overflow: auto;
}

.ViewImage {
  max-width: 700px;
  max-height: calc(90vh - 70px);
}

.rnc__notification-message {
  white-space: pre-line;
}

.react-pdf__Page__textContent {
  height: auto !important;
  width: auto !important;
}

.react-pdf__Page__annotations {
  display: none !important;
}

.ant-dropdown-menu-item-icon {
  font-size: 18px !important;
}
/* -------------------------------------------------------------------------- */
/*                        Client App Management Section                       */
/* -------------------------------------------------------------------------- */
.head-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.client-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: #f4f5f7;
}
.clickable-class {
  vertical-align: middle;
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.text-bold {
  font-weight: 900;
}
#PaginationContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    background-color: #f4f5f7;

    .TextPaging {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #364868;
        margin-right: 15px;
    }

    .Paging {
        display: flex;
        align-items: center;
        margin: 0 15px;
        margin-left: 10px;
        padding-left: 15px;
        padding-right: 5px;
        border: 1px solid #c5cad3;
        border-bottom: none;
        border-top: none;

        .PagingItem {
            border: 1px solid #c5cad3;
            color: #7b809a;
            height: 35px !important;
            width: 35px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            &.Active {
                background: #091e42;
                color: #fff;
            }

            &:hover {
                cursor: pointer;
            }

            &.Disabled {
                opacity: 0.3;
                cursor: default;
            }
        }

        .More {
            display: flex;
            align-items: center;
            font-size: 30px;
            margin-right: 5px;
        }
    }
}
